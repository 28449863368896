import React, { createContext, useState, useEffect } from 'react';
import { Result } from 'antd';
import { API } from '../services/config';

// 1. Crear el contexto de autenticación
export const AuthContext = createContext();

// 2. Crear el proveedor de contexto
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [menuCollapsed, setMenuCollapsed] = useState(true);
    const [userData, setUserData] = useState({});
    const [apiStatus, setApiStatus] = useState({ isConnected: true, message: '' });

    // eslint-disable-next-line no-unused-vars
    const [roles, setRoles] = useState({
        1: [1, 2, 3], // userTypeId 1 can see superuser, admin and user pages
        2: [2, 3], // userTypeId 2 can see admin and user pages
        3: [3], // userTypeId 3 can only see user pages
        // Add more roles here
    });

    const showByRol = {
        exportButton: 2,
        menu: {
            dashboard: 1,
            student: 3,
            enrrollment: 3,
            courses: 2,
            transactions: 3,
            users: 1,
            locations: 2,
        },
        actionMenuTable: {
            show: 3,
            edit: 2,
            delete: 1,
            pay: 3,
            enable: 2,
        },
        users: {
            show: 3,
            editForm: 1,
            delete: 1,
        },
    };

    const checkApi = () => {
        fetch(API).then(response => {
            console.log("🚀 ~ checkApi ~ response:", response)
            if (!response.ok) {
                throw new Error('Error al conectar con la API');
            }
            return response.json();
        }).then(data => {
            setApiStatus({ isConnected: true, message: '' });
        }).catch(error => {
            console.log("🚀 ~ checkApi ~ error:", error)
            setApiStatus({
                isConnected: false,
                message: error.message,
                status: "500",
                title: "Error de conexión",
            });
        });
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        if (token) {
            setIsAuthenticated(true);
            setUserData(JSON.parse(user));
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    if (!apiStatus.isConnected) {
        return <Result status={apiStatus.status} title={apiStatus.status} subTitle={apiStatus.message} />;
    };

    const menuCollapsedState = (value) => {
        setMenuCollapsed(value);
    };

    const setAuthData = (data) => {
        setUserData(data.user);
        setIsAuthenticated(true);
        localStorage.setItem('token', data.token);
        // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJodHRwOi8vMTkyLjE2OC45LjcxOjgwODAvIiwiaWF0IjoxNzEwMzUwNDc0LCJleHAiOjE3MTEyNTA0NzQsInVzZXJJZCI6OSwidXNlck5hbWUiOiJqZWFubWFyaWUiLCJuYW1lIjoiSmVhbm1hcmllIiwibGFzdE5hbWUiOiJnb256YWxleiIsImVtYWlsIjoiamVhbm1hLmpjZ2IxNUBnbWFpbC5jb20iLCJwaG9uZSI6IjQxMjY2NzUwNzAiLCJhdmF0YXIiOiIiLCJ1c2VyVHlwZUlkIjoxfQ.G1ynfNwVrNHuFBy5g9H0WEZILFaewqTN0ERi1cjCW-M');
        localStorage.setItem('user', JSON.stringify(data.user));
    };

    const checkRole = (userTypeId, role) => {
        const userRoles = roles[userTypeId];
        if (userRoles && userRoles.includes(role)) {
            return true;
        };
        return false;
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuthData, userData, menuCollapsed, menuCollapsedState, checkRole, showByRol, checkApi }}>
            {children}
        </AuthContext.Provider>
    );
};