import { THEME_COLOR, TEMPLATE } from '../constants/ThemeConstant';
export const layout = {
    minHeight: TEMPLATE.LAYOUT_MIN_HEIGHT,
    backgroundColor: THEME_COLOR.BACKGROUND,
    padding: TEMPLATE.LAYOUT_CONTENT_GUTTER
}
export const appLayout = {
    padding: TEMPLATE.LAYOUT_CONTENT_GUTTER
}


