import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AUTHENTICATED_ENTRY } from "../configs/AppConfig";
import { AuthContext } from "../context/AuthContext";

const PublicRoutes = () => {
  const { isAuthenticated, userData } = useContext(AuthContext);
  if (isAuthenticated) {
    if (userData.userTypeId !== 1) return <Navigate to="/estudiantes" />;

    return <Navigate to={AUTHENTICATED_ENTRY} />;
  } else {
    return <Outlet />;
  }
};

export default PublicRoutes;
