import React from "react";

const DASHBOARD = "/dashboard";
const ESTUDIANTES = "/estudiantes";
const MATRICULADO = "/matriculado";
const CURSOS = "/cursos";
const TRANSACCIONES = "/transacciones";
const REPORTES = "/reportes";
const USUARIOS = "/usuarios";
const SEDES = "/sedes";

const Login = React.lazy(() => import("../pages/auth/Login/index"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword/index"));
const Dashboard = React.lazy(() => import("../pages/views/dashboard/index"));
const Estudiantes = React.lazy(() => import("../pages/views/Students"));
const Matriculado = React.lazy(() => import("../pages/views/Enrollments"));
const Cursos = React.lazy(() => import("../pages/views/Courses"));
const Transacciones = React.lazy(() => import("../pages/views/Transactions"));
const Reportes = React.lazy(() => import("../pages/views/Reports"));
const Usuarios = React.lazy(() => import("../pages/views/Users"));
const Sedes = React.lazy(() => import("../pages/views/Locations"));


export const publicRoutes = [
  {
    key: "auth",
    path: '/auth',
    component: Login,
  },
  {
    key: "forgotPassword",
    path: `/forgot-password`,
    component: ForgotPassword,
  },
];

export const privateRoutes = [
  { key: "dashboard", path: DASHBOARD, component: Dashboard, },
  { key: "estudiante", path: ESTUDIANTES, component: Estudiantes, },
  { key: "matriculado", path: MATRICULADO, component: Matriculado, },
  { key: "cursos", path: CURSOS, component: Cursos, },
  { key: "transacciones", path: TRANSACCIONES, component: Transacciones, },
  { key: "reportes", path: REPORTES, component: Reportes, },
  { key: "usuarios", path: USUARIOS, component: Usuarios, },
  { key: "sedes", path: SEDES, component: Sedes, },
];