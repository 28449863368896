import { ConfigProvider } from "antd";
import React from "react";
import Layouts from "./layouts";
import { BrowserRouter } from "react-router-dom";
import pallete from "./theme/pallete";
import { AuthProvider } from "./context/AuthContext";
import esES from "antd/lib/locale/es_ES";
const themeConfig = pallete;

const App = () => (

  <ConfigProvider
    theme={{
      token: themeConfig,
      fontFamily: 'Relaway, sans-serif',
      components: {
        Table: {

          headerBg: '#F2F4F7',
          headerColor: themeConfig.colorPrimary,
          headerBorderRadius: 10,
          headerSplitColor: '#ffffff00'


        },
        Menu: {
          // horizontalItemSelectedColor: 'yellow',
          horizontalLineHeight: '10px',
        }
      }
    }}
    locale={esES}
  >
    <BrowserRouter basename={document.querySelector("base")?.getAttribute("href") ?? "/"}>
      <AuthProvider>
        <Layouts />
      </AuthProvider>
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
